import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { OptimizelyProvider, ReactSDKClient, createInstance, setLogLevel } from '@optimizely/react-sdk';

const OptimizelyContext = createContext<{
    optimizelyClient: ReactSDKClient;
    forceUpdateOptimizelyClient: () => void;
}>({
    optimizelyClient: null as unknown as ReactSDKClient,
    forceUpdateOptimizelyClient: () => { }
});

export const useOptimizelyContext = () => {
    return useContext(OptimizelyContext);
};

const OptimizelyContextProvider = (
    { children, userId, mileagePlanNumber }: { children: ReactNode, userId: string, mileagePlanNumber: string }
) => {
    const [optimizelyClient, setOptimizelyClient] = useState<ReactSDKClient>(null as unknown as ReactSDKClient);

    const createOptimizelyClient = useCallback(() => {
        const client = createInstance({
            sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
        });
        client.setUser({
            id: userId,
            attributes: {
                mileagePlanNumber: mileagePlanNumber
            }
        });
        setLogLevel('debug');
        setOptimizelyClient(client);
    }, [userId, mileagePlanNumber]);

    useEffect(() => {
        if (!!userId && !!mileagePlanNumber) {
            createOptimizelyClient();
        }
    }, [userId, mileagePlanNumber, createOptimizelyClient]);

    if (!optimizelyClient) return null;

    return (
        <OptimizelyContext.Provider value={{
            optimizelyClient: optimizelyClient,
            forceUpdateOptimizelyClient: createOptimizelyClient
        }}>
            <OptimizelyProvider
                optimizely={optimizelyClient}
                userId={userId}
                userAttributes={{mileagePlanNumber: mileagePlanNumber}}>
                {children}
            </OptimizelyProvider>
        </OptimizelyContext.Provider>
    );
};

export default OptimizelyContextProvider;