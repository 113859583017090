import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useDecision } from "@optimizely/react-sdk";

const FeatureToggleContext = createContext({
    is_atmos_branding_enabled: false
});

export const useFeatureToggleContext = () => {
    return useContext(FeatureToggleContext);
}

const FeatureToggleProvider = ({ children }: { children: ReactNode }) => {
    const [decision] = useDecision('is_atmos_branding_enabled');
    const { enabled, variables } = decision;
    const [featureToggles, setFeatureToggles] = useState({
        is_atmos_branding_enabled: false
    });

    // Grab any feature toggles we want to set app wide
    useEffect(() => {
        const mileageActivity = variables['mileage_activity'] as boolean ?? false;
        setFeatureToggles({
            is_atmos_branding_enabled: mileageActivity
        });
    }, [enabled, variables]);

    return (
        <FeatureToggleContext.Provider value={featureToggles}>
            {children}
        </FeatureToggleContext.Provider>
    );
};

export default FeatureToggleProvider;