const initialState = {
  Token: {
    Value: "",
    Guid: "",
    AlaskaLoyaltyNumber: "",
  },
  MileagePlanDetails: {
    MemberFullName: "",
    LoyaltyNumber: "",
    MemberBalance: 0,
    TierName: "",
    TierCode: "",
    FraudAlertFlag: false,
  },
  MileagePlanActivity: [
    {
      Date: "10/20/2007",
      Activity: "",
      Bonus: 0,
      "Bonus Points": 10,
      Status: "",
      "Total Points": 0,
    },
  ],
  isSuperUser: false,
  Error: { message: null },
};

export type AccountStateType = typeof initialState;

export default initialState;
