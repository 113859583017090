import React from "react";
import "./App.scss";
import ImportScript from "./hooks/ImportScript";
import MileagePlanData from "./services/MPDataService";
import UserAccountContext from "./context/UserAccountContext";
import { reactPlugin } from "./services/AppInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { mountHeaderFooter } from "./headerfooter";
import OptimizelyContextProvider from "./context/OptimizelyContextProvider";
import FeatureToggleProvider from "./context/FeatureToggleProvider";

const AppContainer = React.lazy(() => import("./components/AppContainer/AppContainer"));

const App = () => {
  // Needed for styling of auro components!
  ImportScript("https://cdn.jsdelivr.net/npm/@alaskaairux/auro-interruption@latest/dist/auro-dialog__bundled.es5.js");
  ImportScript(
    `https://p2pcontent-fd-${process.env.REACT_APP_RELEASE_ENV}.azurefd.net/components/shared/component-base.js`
  );
  ImportScript(
    `https://p2pcontent-fd-${process.env.REACT_APP_RELEASE_ENV}.azurefd.net/components/awards-balance-box/main.js`
  );
  ImportScript(
    `https://p2pcontent-fd-${process.env.REACT_APP_RELEASE_ENV}.azurefd.net/components/expand-results/main.js`
  );

  mountHeaderFooter(document.getElementById("header"), document.getElementById("footer"));

  let mileagePlanData = MileagePlanData();
  return (
    <UserAccountContext.Provider value={mileagePlanData}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <OptimizelyContextProvider 
          userId={mileagePlanData.accountState.Token.Guid} 
          mileagePlanNumber={mileagePlanData.accountState.Token.AlaskaLoyaltyNumber}>
            <FeatureToggleProvider>
              <AppContainer />
            </FeatureToggleProvider>
        </OptimizelyContextProvider>
      </AppInsightsContext.Provider>
    </UserAccountContext.Provider>
  );
};

export default App;
